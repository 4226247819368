import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  faCheckSquare,
  faExclamationTriangle,
  faFireAlt,
  faGraduationCap,
  faChevronCircleUp,
  faFlag,
  faStar,
  faPhone,
  faEnvelope,
  faCopy,
  faSparkles,
  faArrowRight,
  faArrowLeft,
  faCheck,
  faChevronUp,
  faChevronDown,
  faSearch,
  faPencilAlt,
  faExclamationCircle,
  faExclamation,
  faPlus,
  faInboxIn,
  faInboxOut,
  faEnvelopeOpen,
  faMousePointer,
  faExternalLink,
  faProjectDiagram,
  faSackDollar,
  faChalkboardTeacher,
  faTrophy,
  faFileExcel,
  faHouse,
  faChartLineUp,
  faUser
} from "@fortawesome/pro-regular-svg-icons";

const icons = {
  tracking: faChartLineUp,
  student: faGraduationCap,
  user: faUser,
  list: faCheckSquare,
  fire: faFireAlt,
  warning: faExclamationTriangle,
  up: faChevronCircleUp,
  flag: faFlag,
  star: faStar,
  phone: faPhone,
  mail: faEnvelope,
  copy: faCopy,
  sparkles: faSparkles,
  arrowRight: faArrowRight,
  arrowLeft: faArrowLeft,
  check: faCheck,
  faChevronUp: faChevronUp,
  faChevronDown: faChevronDown,
  search: faSearch,
  edit: faPencilAlt,
  exclamationCircle: faExclamationCircle,
  exclamation: faExclamation,
  call: faPhone,
  plus: faPlus,
  inboxIn: faInboxIn,
  inboxOut: faInboxOut,
  envelopeOpen: faEnvelopeOpen,
  mousePointer: faMousePointer,
  close: faTimes,
  externalLink: faExternalLink,
  projectDiagram: faProjectDiagram,
  sackDollar: faSackDollar,
  chalkboardTeacher: faChalkboardTeacher,
  whatsapp: faWhatsapp,
  trophy: faTrophy,
  excel: faFileExcel,
  house: faHouse,
};

export default icons;
