/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useDefault } from "hooks";
import { setActiveModule } from "store/actions/site.actions";
import { createUser } from "services/user.service";
import { UserComponents } from "containers";
import { passwordRecovery } from "services/authService";
import { NNavbarDashboard } from "components/Nimbiv2";
import { CardNimbi, DividerH, RowItem } from "components/Nimbiv2/styled";
import { isLoginGoogle, isLoginMSAL, isLoginSSO } from "utils";
import styles from "containers/User/User.module.css";
import { UserContainerForm, UserHeaderForm } from "containers/User/user.styled";

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userModuleTitle = useDefault("userModuleTitle", "Usuarios");
  const {
    client_area_id,
    client_area_context_by_tracking_object,
  } = useSelector((state) => state.auth.user);
  const { tracking_objects: list_tracking_object } = useSelector(
    (state) => state.user
  );
  const initialValues = {
    username: "",
    email: "",
    last_name1: "",
    last_name2: "",
    is_active: true,
    groups: [],
    profile_image: null,
    teams: [],
    tracking_objects: [],
    user_client_areas: [String(client_area_id)],
    user_tracking_objects: [],
  };

  const { moments } = useSelector((state) => state.crm);

  useEffect(() => {
    if (userModuleTitle)
      dispatch(setActiveModule({ title: userModuleTitle, subtitle: null }));
  }, [dispatch, userModuleTitle]);

  const saveChanges = useCallback(
    async (values, { setSubmitting }) => {
      values.username = values.email;
      values.last_name = `${values.last_name1} ${values.last_name2 ?? ""}`;
      values.user_groups = values.groups.map((groupId) => Number(groupId));
      values.user_campus = values.user_campus
        ? values.user_campus.map((item) => Number(item["campus_id"]))
        : [];
      if (client_area_context_by_tracking_object) {
        values.user_tracking_objects = values.user_tracking_objects
          ? values.user_tracking_objects.map((item) => item["name"])
          : [];
        if (values.user_tracking_objects.includes("Todos")) {
          values.user_tracking_objects = list_tracking_object.map(
            (item) => item.name
          );
        }
      }
      values.user_teams = values.teams.map((item) => Number(item));
      values.user_client_areas = values.user_client_areas.map((clientAreaId) =>
        Number(clientAreaId)
      );
      if (values?.moments?.includes(-1)) {
        values.moments = moments.map((item) => item.id);
      }
      if (isLoginSSO()) {
        values.provider_sso = [];
        if (isLoginMSAL())
          values.provider_sso.push(process.env.REACT_APP_MSAL_PROVIDER_ID);
        if (isLoginGoogle())
          values.provider_sso.push(
            process.env.REACT_APP_GOOGLE_AUTH_PROVIDER_ID
          );
      }

      const { status, title, message } = await createUser(values);

      if (status === 201) {
        toast.success(t("create_user_successfully"));
        await passwordRecovery(values.email);
        history.push("/administracion");
      } else if (status === 400) {
        toast.error(`${title} ${message}`);
        setSubmitting(false);
        return false;
      } else {
        toast.error(t("error_create"));
        setSubmitting(false);
      }
    },
    [
      client_area_context_by_tracking_object,
      list_tracking_object,
      t,
      history,
      moments,
    ]
  );

  return (
    <>
      <NNavbarDashboard />
      <Container fluid style={{ paddingBottom: "90px" }}>
        <CardNimbi className="mt-4" padding="0px">
          <Container
            fluid
            style={{
              paddingTop: "32px",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <UserHeaderForm>
              <RowItem style={{ marginBottom: "25px" }}>
                <Col>
                  <div className={styles.titleUserAction}>
                    {t("create_new_user")}
                  </div>
                </Col>
              </RowItem>
              <RowItem>
                <Col sm={6}>
                  <DividerH />
                </Col>
              </RowItem>
            </UserHeaderForm>
            <UserContainerForm>
              <RowItem style={{ marginBottom: "24px" }}>
                <Col>
                  <div
                    className="font-weight-bold"
                    style={{ fontSize: "var(--font-size-lg)" }}
                  >
                    {t("personal_information_create")}
                  </div>
                </Col>
              </RowItem>
              <UserComponents.UserForm
                formValues={initialValues}
                onFormSubmit={saveChanges}
              />
            </UserContainerForm>
          </Container>
        </CardNimbi>
      </Container>
    </>
  );
};
