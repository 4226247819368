import styled from "styled-components";
export const UserHeaderForm = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`;
export const UserContainerForm = styled.div`
  background: #f8fbff;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 24px;
  padding-right: 24px;
`;
