import { USER_GROUPS } from "routes/helpers";
export const getRole = (user) => {
  for (const group of user.groups) {
    const roleFound = Object.entries(USER_GROUPS).find(
      (item) => item[1].ROLE === group
    );
    if (roleFound !== undefined) {
      return USER_GROUPS[roleFound[0]].ROLE_NAME;
    }
  }
};

export const getRoles = (user) => {
  let roles = [];
  for (let group of user.groups) {
    let keys = Object.keys(USER_GROUPS);
    for (let i = 0; i < keys.length; i++) {
      let data = USER_GROUPS[keys[i]].ROLE_NAME;
      if (USER_GROUPS[keys[i]].ROLE === group) {
        roles.push(data);
      }
    }
  }
  if (roles.length > 0) {
    return roles.join(", ");
  }
  return "-";
};

export const cleanFiltersUser = () => {
  localStorage.removeItem("form_user_filters");
  localStorage.removeItem("filterSet_user");
  localStorage.removeItem("searchUsers");
};

export const validateActionsPermissions = (user, permission) => {
  let permissions = [];
  for (let group of user.groups) {
    let actions_permission =
      USER_GROUPS[group.toUpperCase()]?.ACTIONS_PERMISSION;

    permissions = permissions.concat(actions_permission);
  }
  let listPermissions = permission.split(",");
  for (let i = 0; i < listPermissions.length; i++) {
    if (permissions.includes(listPermissions[i])) {
      return true;
    }
  }
  return false;
};
