import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  PageInitial,
  PageSecond,
  PageThird,
  PageFourth,
  PageFifth,
  PageSecondCopy,
} from "containers/Initiatives/pages";
import {
  NNavbarDashboard,
  ScrollToTopOnMount,
  NStepContainer,
} from "components/Nimbiv2";
import {
  validateAddInfo,
  validatePageTemplate,
  validateSelectStudent,
  validateStepFile,
  validateStepFirst,
  validateStepInitial,
  validateStudentResponse,
} from "containers/Initiatives/pages/validations";
import { fetchClientFilters } from "store/actions/async/site-async.action";
import { APP_MODULES, deleteLocalStorage, getGlobalConfig } from "utils";
import {
  cleanFilter,
  loadClientFilters,
  setActiveModule,
} from "store/actions/site.actions";
import PageFourthFile from "containers/Initiatives/pages/PageFourthFile";
import PageFirst from "containers/Initiatives/pages/PageFirst";
import { cleanStudentTrackingInitiative } from "store/actions/initiative.actions";
import { useHistory } from "react-router-dom";
import RouteLeavingGuard from "components/Nimbiv2/RouteLeavingGuard";
import PageSelectQuestion from "containers/Initiatives/pages/PageSelectQuestion";
import { getQuestionsInitiative } from "services/initiativeService";
import PageStudentResponse from "containers/Initiatives/pages/CardsResume/PageStudentResponse";
import { getStudentResponsesInitiative } from "services/initiativeService";

const positionsForm = {
  normal: [
    "selectInitiative",
    "selectType",
    "addInfo",
    "selectTemplate",
    "selectStudent",
    "resume",
  ],
  external: [
    "selectInitiative",
    "selectType",
    "addFile",
    "addInfo",
    "selectTemplate",
    "resume",
  ],
  clone: [
    "selectInitiative",
    "selectType",
    "selectClone",
    "addInfo",
    "selectStudent",
    "resume",
  ],
};

const descriptionStep = {
  selectInitiative: "Seleccionar como<br/> empezar campaña",
  selectType: "Seleccionar tipo de<br/> campaña",
  addInfo: "Completar <br/> información",
  selectTemplate: "Elegir<br/> plantilla",
  selectStudent: "Seleccionar alumnos<br/> y definir criterio",
  resume: "Revisar información<br/> y confirmar",
  addFile: "Subir archivo",
  selectClone: "Seleccionar campaña<br/> a clonar",
  selectQuestion: "Seleccionar<br/> Cuestionario",
  selectStudentResponse: "Respuesta de estudiante",
};

const CreateInitiative = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form, setForm] = useState({});
  const [position, setPosition] = useState(0);
  const [typeForm, setTypeForm] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [stepActive, setStepActive] = useState(["selectInitiative"]);
  const [errors, setErrors] = useState({});
  const [bannerBlocked, setBannerBlockeed] = useState(false);
  const history = useHistory();
  const [questions, setQuestions] = useState(null);
  const [studentResponses, setStudentResponse] = useState(null);
  const [countCall, setCountCall] = useState(0);

  const fetchQuestionInitiative = async () => {
    const { data } = await getQuestionsInitiative();
    setQuestions(data);
  };

  const fetchStudentResponses = async () => {
    const { data } = await getStudentResponsesInitiative();
    setStudentResponse(data);
  };

  useEffect(() => {
    if (countCall === 0) {
      dispatch(loadClientFilters());
      dispatch(cleanFilter("initiative"));
      dispatch(cleanStudentTrackingInitiative());
      dispatch(fetchClientFilters({ module: APP_MODULES.INITIATIVES.ID }));
      setCountCall(1);
    }
  }, [dispatch, t, countCall]);

  useEffect(() => {
    dispatch(
      setActiveModule({
        title: t("new_initiative"),
        subtitle: null,
        breadcrumbles: [
          {
            title: _.upperFirst(t("initiatives")),
            url: "/campañas",
          },
          {
            title: t("create_initiative"),
            url: null,
          },
        ],
      })
    );
  }, [dispatch, t]);

  useEffect(() => {
    if (!questions) {
      fetchQuestionInitiative();
    }
  }, [questions]);

  useEffect(() => {
    if (!studentResponses) {
      fetchStudentResponses();
    }
  }, [studentResponses]);

  useEffect(() => {
    if (form.optionCreate === "copy") {
      setTypeForm(positionsForm["clone"]);
    } else {
      if (form.optionInitiative === "Call") {
        let newStructureForm = [];
        if (form.source_type === "external") {
          newStructureForm = [...positionsForm["external"]];
        } else {
          newStructureForm = [...positionsForm["normal"]];
        }
        if (
          getGlobalConfig("enable_question_initative", false) &&
          form.source_type === "internal"
        ) {
          newStructureForm.splice(4, 0, "selectQuestion");
        }
        if (getGlobalConfig("enabled_select_student_responses", false)) {
          if (form.source_type === "external") {
            newStructureForm.splice(5, 0, "selectStudentResponse");
          } else {
            newStructureForm.splice(4, 0, "selectStudentResponse");
          }
        }
        setTypeForm(newStructureForm);
      } else {
        if (form.source_type === "external") {
          setTypeForm(positionsForm["external"]);
        } else {
          setTypeForm(positionsForm["normal"]);
        }
      }
    }
  }, [form.source_type, form.optionCreate, form.optionInitiative]);

  const onChangeForm = (
    key,
    value,
    data,
    next = false,
    reemplaceAll = false,
    initial = false,
    deleteKeys
  ) => {
    if (key && value) {
      if (key.includes("template")) {
        let errorsRemove = errors;
        delete errorsRemove["required_select_template"];
        setErrors(errorsRemove);
      } else {
        let errorsRemove = errors;
        delete errorsRemove[key];
        setErrors(errorsRemove);
      }
    }

    if (key === "selectAll") {
      setStepActive(["selectInitiative", "selectType", "selectClone"]);
    }

    if (key === "optionCreate") {
      setStepActive(["selectInitiative"]);
      deleteLocalStorage("initiative_filters");
    }

    if (key === "optionInitiative") {
      setStepActive(["selectInitiative", "selectType"]);
      deleteLocalStorage("initiative_filters");
    }

    if (reemplaceAll) {
      setForm(data);
    } else {
      if (data) {
        let newFormData = { ...form, [key]: value, ...data };
        if (deleteKeys) {
          for (let keys in deleteKeys) {
            delete newFormData[deleteKeys[keys]];
          }
        }
        setForm(newFormData);
      } else {
        setForm({ ...form, [key]: value });
      }
    }
    if (next) {
      setPosition(position + 1);
    }
    if (initial) {
      let new_data = { ...form, [key]: value, ...data };
      let keys = Object.keys(new_data);
      let array_keys_protected = [
        "initiative_type",
        "optionInitiative",
        "optionCreate",
      ];
      for (let i = 0; i < keys.length; i++) {
        if (!array_keys_protected.includes(keys[i])) {
          delete new_data[keys[i]];
        }
      }
      setForm(new_data);
    }
  };

  const isEmpty = (item) => {
    return Object.keys(item).length === 0;
  };

  const validationFunctions = {
    selectInitiative: validateStepFirst,
    selectType: validateStepInitial,
    addFile: validateStepFile,
    addInfo: validateAddInfo,
    selectTemplate: validatePageTemplate,
    selectStudent: validateSelectStudent,
    selectStudentResponse: validateStudentResponse,
  };

  const nextValidate = useCallback(
    (position, new_position = null) => {
      let posText = typeForm[position];
      if (!(new_position !== null && new_position < position)) {
        if (validationFunctions.hasOwnProperty(posText)) {
          let fun = validationFunctions[posText];
          let value = fun(form);
          if (!isEmpty(value)) {
            setErrors(value);
            return;
          }
        }
      }
      if (new_position !== null) {
        setPosition(new_position);
      } else {
        setPosition(position + 1);
        if (!stepActive.includes(typeForm[position + 1])) {
          setStepActive([...stepActive, typeForm[position + 1]]);
        }
      }
    },
    [typeForm, form, stepActive, validationFunctions]
  );

  const onChangePosition = (value) => {
    if (value === "next") nextValidate(position);
    if (value === "last") setPosition(position - 1);
  };

  const validateAll = () => {
    let warning = typeForm.filter((item) => {
      if (validationFunctions[item]) {
        let fun = validationFunctions[item];
        let value = fun(form);
        return !isEmpty(value);
      }
      return false;
    });
    setWarnings(warning);
    return warning.length > 0;
  };

  const changeNavigate = useCallback(
    (path) => {
      history.push(path);
    },
    [history]
  );

  useEffect(() => {
    if (typeForm[position] === "selectType") {
      setBannerBlockeed(true);
    }
  }, [position, typeForm]);

  return (
    <div>
      <RouteLeavingGuard
        title={t("discard_initiative")}
        detail={t("detail_discard_initiative")}
        when={bannerBlocked}
        navigate={changeNavigate}
        textCancel={_.upperFirst(t("cancel"))}
        textAccept={_.upperFirst(t("discard"))}
      />
      <ScrollToTopOnMount />
      <NNavbarDashboard breadcrumbles={true}></NNavbarDashboard>
      <Container style={{ marginTop: "24px" }}>
        <NStepContainer
          items={typeForm}
          position={position}
          activeSteps={stepActive}
          onChangeStep={(new_position, item) => {
            if (stepActive.includes(item)) {
              nextValidate(position, new_position);
            }
          }}
          descriptions={descriptionStep}
          warnings={warnings}
        />
      </Container>
      <Container style={{ marginTop: "24px" }}>
        {typeForm[position] === "selectInitiative" && (
          <PageFirst
            pageNumber={t("step_number", { number: position + 1 })}
            onChangeForm={onChangeForm}
            onChange={onChangePosition}
            formValues={form}
            errors={errors}
          ></PageFirst>
        )}
        {typeForm[position] === "selectType" && (
          <PageInitial
            pageNumber={t("step_number", { number: position + 1 })}
            onChangeForm={onChangeForm}
            onChange={onChangePosition}
            formValues={form}
            errors={errors}
          ></PageInitial>
        )}
        {typeForm[position] === "addFile" && (
          <PageFourthFile
            pageNumber={t("step_number", { number: position + 1 })}
            onChangeForm={onChangeForm}
            onChange={onChangePosition}
            formValues={form}
            errors={errors}
          ></PageFourthFile>
        )}
        {typeForm[position] === "addInfo" &&
          form.optionCreate &&
          form.optionCreate === "new" && (
            <PageSecond
              pageNumber={t("step_number", { number: position + 1 })}
              descriptionStep={t("add_information_create_initiative")}
              onChangeForm={onChangeForm}
              onChange={onChangePosition}
              formValues={form}
              errors={errors}
            ></PageSecond>
          )}
        {typeForm[position] === "selectClone" && (
          <PageSecondCopy
            pageNumber={t("step_number", { number: position + 1 })}
            onChange={onChangePosition}
            onChangeForm={onChangeForm}
            formValues={form}
            errors={errors}
          ></PageSecondCopy>
        )}
        {typeForm[position] === "selectTemplate" && (
          <PageThird
            pageNumber={t("step_number", { number: position + 1 })}
            onChangeForm={onChangeForm}
            onChange={onChangePosition}
            formValues={form}
            errors={errors}
          ></PageThird>
        )}
        {typeForm[position] === "selectStudentResponse" && (
          <PageStudentResponse
            pageNumber={t("step_number", { number: position + 1 })}
            onChangeForm={onChangeForm}
            onChange={onChangePosition}
            formValues={form}
            errors={errors}
            studentResponses={studentResponses ?? []}
          ></PageStudentResponse>
        )}
        {typeForm[position] === "selectQuestion" && (
          <PageSelectQuestion
            pageNumber={t("step_number", { number: position + 1 })}
            descriptionStep={"Seleciona el cuestionario asociado a tu campaña"}
            onChangeForm={onChangeForm}
            onChange={onChangePosition}
            formValues={form}
            errors={errors}
            questions={questions}
          ></PageSelectQuestion>
        )}
        {typeForm[position] === "addInfo" &&
          form.optionCreate &&
          form.optionCreate === "copy" && (
            <PageSecond
              pageNumber={t("step_number", { number: position + 1 })}
              descriptionStep={t("add_information_create_initiative")}
              onChangeForm={onChangeForm}
              onChange={onChangePosition}
              formValues={form}
              errors={errors}
            ></PageSecond>
          )}
        {typeForm[position] === "selectStudent" && (
          <PageFourth
            pageNumber={t("step_number", { number: position + 1 })}
            onChangeForm={onChangeForm}
            onChange={onChangePosition}
            formValues={form}
            errors={errors}
          ></PageFourth>
        )}
        {typeForm[position] === "resume" && (
          <PageFifth
            pageNumber={t("step_number", { number: position + 1 })}
            onChange={onChangePosition}
            setPosition={(position) => {
              setPosition(position);
            }}
            formValues={form}
            validateAll={validateAll}
            sucesssSave={(state) => {
              setBannerBlockeed(false);
            }}
            typeFormList={typeForm}
          ></PageFifth>
        )}
      </Container>
    </div>
  );
};
export default CreateInitiative;
