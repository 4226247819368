import * as TaskAction from "store/actions/task.actions";
import { handleErrorStatus } from "services/errors";
import { cleanNullJson } from "utils";
import {
  getTaskDetail,
  getInfoCustomPerson,
  getTaskQuestions,
} from "services/taskService";
import { dispatchMultiTaskEvent, dispatchTaskEvent } from "services/crmService";
import { fetchLastNote } from "services/student.service";

const handleErrorResponse = (err) => {
  const status = err instanceof Map ? err.get("status") : 500;
  if (status > 0) return handleErrorStatus(status);
};

export const fetchTask = (id, initialParams = {}) => {
  return (dispatch) => {
    dispatch(TaskAction.getTask());
    getTaskDetail(id, cleanNullJson(initialParams))
      .then(async ({ data }) => {
        if (data && data.student_tracking && data.student_tracking.student_id) {
          const response = await fetchLastNote(
            data.student_tracking.student_id,
            {
              top: 1,
            }
          );
          if (response && response.status === 200) {
            data.student_tracking.recentNote =
              response.data.results.length > 0
                ? response.data.results[0]
                : null;
          }
        }
        dispatch(TaskAction.setTask(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
      });
  };
};

export const fetchInfoCustomPerson = (id, initialParams = {}) => {
  return (dispatch) => {
    dispatch(TaskAction.getCustomPerson());
    getInfoCustomPerson(id, cleanNullJson(initialParams))
      .then(({ data }) => {
        dispatch(TaskAction.setCustomPerson(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
      });
  };
};

export const fetchTaskQuestions = (id, initialParams = null) => {
  return (dispatch) => {
    dispatch(TaskAction.getTaskQuestions());
    getTaskQuestions(id, cleanNullJson(initialParams))
      .then(({ data }) => {
        dispatch(TaskAction.setTaskQuestions(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
      });
  };
};

export const fetchProcessTaskRequest = (row, action) => {
  return (dispatch) => {
    const payload = {
      action,
      channel: process.env.REACT_APP_PUSHER_CHANNEL,
      event: process.env.REACT_APP_PUSHER_EVENT,
    };
    dispatchTaskEvent(row.id, payload, row);
  };
};

export const fetchProcessMulTaskRequest = (rows, action) => {
  return (dispatch) => {
    const payload = {
      action,
      channel: process.env.REACT_APP_PUSHER_CHANNEL,
      event: process.env.REACT_APP_PUSHER_EVENT,
      tasks: rows.split(","),
    };
    dispatchMultiTaskEvent(payload);
  };
};
