import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faPhone,
  faEllipsisV,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";

import { backgroundMoment } from "assets/images";
import { AnonymousContainer } from "components/Nimbiv2/styled";
import { NButton } from "components/Nimbiv2";
import { NBadge } from "components";
import {
  CardContainer,
  CardHeader,
  CardBody,
  CardTitle,
  RowDetail,
  CardDescription,
} from "../CardGeneral";
import { colorsBadge } from "utils/constants";
import { useTranslationLocal } from "hooks";

const CardMoment = ({ position, moment, onChange, isAnonymous }) => {
  let history = useHistory();
  const { translation } = useTranslationLocal();
  const { t } = useTranslation();
  return (
    <CardContainer style={{ height: "100%" }}>
      <CardHeader
        img={backgroundMoment}
        height="62px"
        sizeCircle="45px"
        sizeTextCircle="10px"
      >
        <div className="card-options d-flex justify-content-center align-items-center">
          <div className="mr-auto">
            <NBadge
              text={
                moment.active_student_tracking_count === 0
                  ? _.upperFirst(t("inactive"))
                  : _.upperFirst(t("active"))
              }
              newColor={
                moment.active_student_tracking_count === 0
                  ? colorsBadge.light.inactive
                  : colorsBadge.light.success
              }
              customStyle={{
                borderRadius: "64px",
              }}
            />
          </div>
          {1 === 0 && (
            <FontAwesomeIcon icon={faEllipsisV} size="1x" color="white" />
          )}
        </div>
        {/* <div className="image-circle">PA{position}</div> */}
      </CardHeader>
      <CardBody padding="5px 24px 24px 24px">
        <CardTitle className="text-center">
          <AnonymousContainer isAnonymous={isAnonymous}>
            {moment.name}
          </AnonymousContainer>
        </CardTitle>
        <CardDescription>
          {moment.description && moment.description.length > 181
            ? moment.description.slice(0, 178) + "..."
            : moment.description}
        </CardDescription>
        <div className="mt-auto">
          <RowDetail style={{ marginTop: "11px", marginBotton: "9px" }}>
            <FontAwesomeIcon
              icon={faGraduationCap}
              style={{ marginRight: "6px", width: "18px" }}
            />
            {translation("students", "startCase", t("students"))}:{" "}
            <strong className="colorTextSecondary">
              {" "}
              {moment.active_student_tracking_count}
            </strong>
          </RowDetail>
          {moment?.count_workflow_call > 0 && (
            <RowDetail>
              <FontAwesomeIcon
                icon={faPhone}
                style={{ marginRight: "6px", width: "18px" }}
              />
              {_.upperFirst(translation("called", "", t("called")))}:{" "}
              <strong className="colorTextSecondary">
                {moment.call_active_tasks}
              </strong>
            </RowDetail>
          )}
          <RowDetail>
            <FontAwesomeIcon
              icon={faUsers}
              style={{ marginRight: "6px", width: "18px" }}
            />
            {_.upperFirst(t("team"))}:{" "}
            <strong className="colorTextSecondary">
              {moment.team ? moment.team.name : "-"}
            </strong>
          </RowDetail>
          <NButton
            disabled={moment.active_student_tracking_count === 0}
            onClick={() => {
              onChange(moment.id);
            }}
            style={{
              width: "100%",
              borderRadius: "24px",
              marginTop: "20px",
              lineHeight: "19.6px",
            }}
            outlined
            color="second"
          >
            {t("Go to tasks")}
          </NButton>
          <ButtonCard
            transparent
            color="second"
            style={{ marginTop: "10px" }}
            onClick={() => {
              history.push(`/planes-de-accion/${moment.id}/`);
            }}
          >
            {t("See detail")}
          </ButtonCard>
        </div>
      </CardBody>
    </CardContainer>
  );
};
export default CardMoment;

const ButtonCard = styled(NButton)`
  width: 100%;
  border-radius: 24px;
  margin-top: 8px;
  ${(props) =>
    props.boxshadow
      ? `box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);`
      : ""}
`;
