import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Container, Dropdown } from "react-bootstrap";

import { setActiveModule } from "store/actions/site.actions";
import { DropdownTransparent, RowItem } from "components/Nimbiv2/styled";
import { PageInformation, PageStudents } from "containers/Moments/detail";
import { NNavbarDashboard } from "components/Nimbiv2";
import { fetchMoment } from "store/actions/async/moment-async.actions";
import { fetchMoments } from "store/actions/async/crm-async.action";
import { TabContainer } from "components";
import { useTranslationLocal } from "hooks";
import PageDiagram from "containers/Moments/detail/PageDiagram";
import { verifyPermissionTab } from "utils";

const MomentDetail = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();

  const { moment } = useSelector((state) => state.moment);
  const { moments } = useSelector((state) => state.crm);
  const [tab, setTab] = useState("information");

  useEffect(() => {
    if (moments.length === 0) {
      dispatch(fetchMoments());
    }
  }, [dispatch, moments]);

  useEffect(() => {
    dispatch(
      setActiveModule({
        title: t("action_plan_detail"),
        subtitle: null,
        breadcrumbles: [
          {
            title: _.upperFirst(t("action_plans")),
            url: "/planes-de-accion",
          },
          {
            title: t("action_plan_detail"),
            url: null,
          },
        ],
      })
    );
    dispatch(fetchMoment(id));
  }, [dispatch, id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (moment) {
      dispatch(
        setActiveModule({
          title: moment.name,
          subtitle: null,
          breadcrumbles: [
            {
              title: _.upperFirst(t("action_plans")),
              url: "/planes-de-accion",
            },
            {
              title: t("initiative_title_detail", { text: moment.name }),
              url: null,
            },
          ],
        })
      );
    }
  }, [moment, dispatch, t]);

  const changeMomentDetail = (value) => {
    history.push(`/planes-de-accion/${value}/`);
  };

  const actionPlanStatus = (momentItem) => {
    return momentItem.active_student_tracking_count === 0
      ? _.upperFirst(t("inactive"))
      : _.upperFirst(t("active"));
  };

  let tabsInclude = [];
  if (verifyPermissionTab("tabMomentDetail", "tab_diagram")) {
    tabsInclude.push({
      key: "diagram",
      title: _.upperFirst(t("diagram")),
    });
  }
  let tabs = [
    {
      key: "information",
      title: _.upperFirst(t("information")),
    },
    {
      key: "students",
      title: _.upperFirst(translation("students", "", t("students"))),
    },
    ...tabsInclude,
  ];

  const momentSelected = useMemo(() => {
    return moments.find((moment) => moment.id === Number(id));
  }, [moments, id]);

  return (
    <>
      <NNavbarDashboard
        breadcrumbles={true}
        textwhite={1}
        activeCircle={true}
      />
      <Container fluid style={{ marginTop: "72px", paddingBottom: "40px" }}>
        <RowItem>
          <Col xs="8" style={{ paddingLeft: "0px" }}>
            <TabContainer
              tabs={tabs}
              onSelect={(tabSelected) => {
                setTab(tabSelected);
              }}
            />
          </Col>
          <Col xs="1"></Col>
          <Col
            xs="3"
            style={{ paddingRight: "0px" }}
            className="d-flex align-items-center justify-content-end"
          >
            <DropdownTransparent
              style={{ minWidth: "180px" }}
              onSelect={changeMomentDetail}
            >
              {momentSelected && (
                <Dropdown.Toggle variant="secundary" id="dropdown-basic">
                  <div style={{ width: "90%", display: "flex" }}>
                    <div
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        marginRight: "5px",
                      }}
                    >
                      {momentSelected?.name}
                    </div>
                    - {actionPlanStatus(momentSelected)}
                  </div>
                </Dropdown.Toggle>
              )}
              {!momentSelected && (
                <Dropdown.Toggle variant="secundary" id="dropdown-basic">
                  -
                </Dropdown.Toggle>
              )}

              <Dropdown.Menu>
                {moments &&
                  moments.map((moment, key) => {
                    return (
                      <Dropdown.Item
                        active={moment?.id === Number(id)}
                        eventKey={moment.id}
                        key={`option-action-${key}`}
                      >
                        {moment.name} - {actionPlanStatus(moment)}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </DropdownTransparent>
          </Col>
        </RowItem>
        {tab === "information" && <PageInformation />}
        {tab === "students" && <PageStudents />}
        {tab === "diagram" && <PageDiagram />}
      </Container>
    </>
  );
};

export default MomentDetail;
