import React, { useEffect, useState } from "react";
import { CardNimbi } from "components/Nimbiv2/styled";
import 'react-notion-x/src/styles.css'
import 'prismjs/themes/prism-tomorrow.css'
import './notion.css'
import { NotionRenderer } from "react-notion-x";
import { fetchPageNotion } from "services/help.service"


const ManualContainer = () => {
  const [blockMap, setBlockMap] = useState(null);
  const getPageNotion = async () => {
    const {data, status} = await fetchPageNotion("145f0844377d8012b075cfba67e078c0")
    if (status === 200){
      setBlockMap({'block': data})
    }
  };

  useEffect(() => {
    getPageNotion();
  }, []);

  useEffect(() => {
    const handleClick = (e) => {
      e.preventDefault();
      let t = null;
      if (e.target.tagName === 'SPAN'){
        t = e.target.parentElement;
      }
      else{
        t = e.target;
      }
      const href_split = t.href.split('#')
      const id_target = href_split[href_split.length - 1]
      const target = document.querySelector("[data-id='"+id_target+"']");

      if (target) {
        target.scrollIntoView({behavior: "smooth"});
      }
      return false;
    };
    const handleClick2 = (e) => {
      e.preventDefault();
      return false;
    };
    const href_list = document.getElementsByClassName('notion-table-of-contents-item');
    const hash_link = document.getElementsByClassName('notion-hash-link');
    for(let i = 0; i < href_list.length; i++){
      href_list[i].addEventListener("click", handleClick);
    }
    for(let i = 0; i < hash_link.length; i++){
      hash_link[i].addEventListener("click", handleClick2);
      hash_link[i].style.display = 'none';
    }
    return () => {
      for(let i = 0; i < href_list.length; i++){
        href_list[i].removeEventListener("click", handleClick);
      }
      for(let i = 0; i < hash_link.length; i++){
        hash_link[i].removeEventListener("click", handleClick2);
      }
    } 
  }, [blockMap]);
  return (
    <div>
      <CardNimbi
        padding="24px"
        style={{ minHeight: "75vh", borderRadius: "8px" }}
        className="d-flex justify-content-center align-items-center"
      >
        <div className="d-flex align-items-center flex-column">
        { blockMap && <NotionRenderer
          recordMap={blockMap}
          fullPage={true}
          showTableOfContents={true}
        />
        }
        </div>
      </CardNimbi>
    </div>
  );
};
export default ManualContainer;
