/* eslint-disable no-param-reassign */
import React, { useEffect, useCallback, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Container, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useDefault } from "hooks";
import { SmallForm } from "components/Skeletons";
import { setActiveModule } from "store/actions/site.actions";
import { getUser, editUser } from "services/user.service";
import { UserComponents } from "containers";
import { NNavbarDashboard } from "components/Nimbiv2";
import { CardNimbi, DividerH, RowItem } from "components/Nimbiv2/styled";
import styles from "containers/User/User.module.css";
import { fetchUserTrackingObjects } from "store/actions/async/user-async.actions";
import { UserContainerForm, UserHeaderForm } from "containers/User/user.styled";

export default ({ userID }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userModuleTitle = useDefault("userModuleTitle", "Usuarios");
  const [isPending, setPending] = useState(true);
  const [userProfile, setUserProfile] = useState({});
  const { tracking_objects: list_tracking_object } = useSelector(
    (state) => state.user
  );
  const { moments } = useSelector((state) => state.crm);
  const user = useSelector((state) => state.auth.user);
  const { campus: CampusStudent, listCampus: Campuses } = useSelector(
    (state) => state.crm,
    shallowEqual
  );

  const listCampus = useMemo(() => {
    return CampusStudent && CampusStudent.length > 0
      ? CampusStudent
      : Campuses
      ? Campuses.map((item, key) => {
          return { campus: item.name, campus_id: item.id };
        })
      : null;
  }, [CampusStudent, Campuses]);

  const fetchUser = useCallback(async () => {
    if (listCampus) {
      const { status, data = {} } = await getUser(userID);
      setPending(false);
      if (status === 404) {
        toast.error(t("user_not_found"));
        history.push("/administracion");
      }

      if (status === 200) {
        const {
          last_name: lastName = "",
          groups: currentGroups,
          user_client_areas: currentClientAreas,
          tracking_objects,
        } = data;
        const finalProfile = {
          ...data,
          last_name1: lastName.split(" ")[0],
          last_name2: lastName.split(" ")[1],
          is_active: data.is_active,
          groups: currentGroups.map((group) => String(group.id)),
          user_client_areas: currentClientAreas.map((client) =>
            String(client.client_area.pk)
          ),
          moments: data?.moments ?? [],
        };
        if (user.client_area_context_by_tracking_object) {
          finalProfile["user_tracking_objects"] = tracking_objects;
        }
        let list_camp = [];
        if (listCampus.length > 0) {
          if (finalProfile.campus && listCampus) {
            if (finalProfile.campus.length !== listCampus.length) {
              for (const i in finalProfile.campus) {
                for (const y in listCampus) {
                  if (
                    Number(listCampus[y].campus_id) === finalProfile.campus[i]
                  ) {
                    list_camp.push(listCampus[y]);
                  }
                }
              }
              finalProfile.user_campus = list_camp;
            } else {
              list_camp.push({ campus: "Todos", campus_id: -1 });
              finalProfile.user_campus = listCampus;
            }
          }
          let itemCampus = finalProfile.campus.filter((itemCampus) => {
            let list_items = listCampus.filter(
              (item) => Number(item.campus_id) === itemCampus
            );
            return list_items.length === 0;
          });

          finalProfile.other_campus = itemCampus;

          if (user.client_area_context_by_tracking_object) {
            dispatch(
              fetchUserTrackingObjects({
                campus_ids:
                  finalProfile.campus.length !== listCampus.length
                    ? finalProfile.user_campus
                        .map((item) => item.campus_id)
                        .join(",")
                    : "-1",
              })
            );
          }
        } else {
          finalProfile.user_campus = [];
          finalProfile.other_campus = [];
        }

        setUserProfile(finalProfile);
      } else {
        toast.error(t("user_could_not_be_obtained"));
        history.push("/administracion");
      }
    }
  }, [history, userID, listCampus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userModuleTitle)
      dispatch(setActiveModule({ title: userModuleTitle, subtitle: null }));
  }, [dispatch, userModuleTitle]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const saveChanges = async (values, { setSubmitting }) => {
    values.username = values.email;
    values.last_name = `${values.last_name1} ${values.last_name2 ?? ""}`;
    values.user_groups = values.groups.map((groupId) => Number(groupId));
    let new_campus = values.user_campus
      ? values.user_campus.map((item) => Number(item["campus_id"]))
      : [];
    let new_teams = values.teams.map((item) => Number(item));
    values.user_client_areas = values.user_client_areas.map((clientAreaId) =>
      Number(clientAreaId)
    );

    if (user.client_area_context_by_tracking_object) {
      values.user_tracking_objects = values.user_tracking_objects
        ? values.user_tracking_objects.map((item) => item["name"])
        : [];
      if (values.user_tracking_objects.includes("Todos")) {
        values.user_tracking_objects = list_tracking_object.map(
          (item) => item.name
        );
      }
    }

    values.user_teams = new_teams.concat(values.others_items_clientarea);
    values.user_campus = new_campus.concat(values.other_campus);
    if (values?.moments?.includes(-1)) {
      values.moments = moments.map((item) => item.id);
    }
    delete values["others_items_clientarea"];
    delete values["other_campus"];
    const { status, title, message } = await editUser(values);

    if (status === 200) {
      toast.success(t("user_was_successfully_modified"));
      history.push("/administracion");
    } else if (status === 400) {
      toast.error(`${title} ${message}`);
      setSubmitting(false);
      return false;
    } else {
      toast.error(t("error_update"));
      setSubmitting(false);
    }
  };

  const showForm = React.useMemo(() => {
    return !isPending && Object.entries(userProfile).length > 0;
  }, [isPending, userProfile]);

  return (
    <>
      <NNavbarDashboard />
      <Container fluid style={{ paddingBottom: "90px" }}>
        <CardNimbi className="mt-4" padding="0px">
          <Container
            fluid
            style={{
              paddingTop: "32px",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <UserHeaderForm>
              <RowItem style={{ marginBottom: "24px" }}>
                <Col>
                  <div className={styles.titleUserAction}>{t("edit_user")}</div>
                </Col>
              </RowItem>
              <RowItem>
                <Col sm={6}>
                  <DividerH />
                </Col>
              </RowItem>
            </UserHeaderForm>
            <UserContainerForm>
              <RowItem style={{ marginBottom: "24px" }}>
                <Col>
                  <div
                    className="font-weight-bold"
                    style={{ fontSize: "var(--font-size-lg)" }}
                  >
                    {t("personal_information_create")}
                  </div>
                </Col>
              </RowItem>

              {showForm ? (
                <UserComponents.UserForm
                  formValues={userProfile}
                  onFormSubmit={saveChanges}
                  isDisabled={isPending}
                  isEditing
                  fetchUser={fetchUser}
                  isEdit={true}
                />
              ) : (
                <RowItem>
                  <Col sm="12">
                    <SmallForm />
                  </Col>
                </RowItem>
              )}
            </UserContainerForm>
          </Container>
        </CardNimbi>
      </Container>
    </>
  );
};
