import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { applyInitiativeFilter } from "utils/moments";
import { ColLargeScreen, RowItem } from "components/Nimbiv2/styled";
import CardInitiative from "components/Nimbiv2/CardInitiative";

const InitiativesList = ({ iniciatives }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  return (
    <RowItem>
      {iniciatives &&
        iniciatives.map((item, position) => {
          return (
            <ColLargeScreen
              key={`card-iniciative-${item.pk}`}
              xl="3"
              lg="4"
              style={{ marginTop: "20px" }}
            >
              <CardInitiative
                item={item}
                position={position + 1}
                onClickDetail={() => {
                  history.push(`/campañas/${item.pk}/`);
                }}
                onClickTask={() => {
                  localStorage.removeItem("filters");
                  localStorage.removeItem("clientFilters");
                  applyInitiativeFilter(
                    dispatch,
                    item.pk,
                    10,
                    false,
                    true,
                    item.source_type
                  );
                  history.push("/tareas");
                }}
                isAnonymous={user.anonymous}
              />
            </ColLargeScreen>
          );
        })}
    </RowItem>
  );
};

export default InitiativesList;
