import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faCog,
  faExternalLinkAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { useDefault } from "hooks";
import { toggleSidebar } from "store/actions/site.actions";
import { markMoment, setTabSelectTask } from "store/actions/crm.actions";
import { getTaskList } from "store/actions/async/crm-async.action";
import { fetchClientFilters } from "store/actions/async/site-async.action";
import ProtectedBlock from "routes/ProtectedBlock";
import {
  getSessionFilters,
  getDataFromLocalStorage,
  setDataToLocalStorage,
  APP_MODULES,
  setSessionFilters,
} from "utils";
import styles from "./NSidebar.module.css";
const { DASHBOARD, TASK, STUDENT, REPORT, USER, MOMENT } = APP_MODULES;

const NSidebar = () => {
  const dispatch = useDispatch();
  const isOpened = useSelector((state) => state.site.isSidebarOpen);
  const { user } = useSelector((state) => state.auth);
  const {
    moments = [],
    pagination: { pageSize },
  } = useSelector((state) => state.crm);

  const dashboardMenuTitle = useDefault("dashboardMenuTitle", "Dashboard");
  const taskMenuTitle = useDefault("taskMenuTitle", "Tareas");
  const studentMenuTitle = useDefault("studentMenuTitle", "Alumnos");
  const reportMenuTitle = useDefault("reportMenuTitle", "Reportes");
  const userMenuTitle = useDefault("userMenuTitle", "Usuarios");
  const momentMenuTitle = useDefault("momentMenuTitle", "Momentos");

  const onToggleSidebar = () => {
    dispatch(toggleSidebar(!isOpened));
  };

  const closeSidebar = (targetUrl = "") => {
    dispatch(toggleSidebar(!isOpened));
    dispatch(toggleSidebar(false, "isScriptSidebarOpen"));

    if (targetUrl === TASK.URL) {
      setDataToLocalStorage("tabTaskSelected", { tab: "moments" });
      dispatch(setTabSelectTask("moments"));
      setDataToLocalStorage("avoidReloadTasks", true);
      const queryParams = getSessionFilters(TASK.FILTER_NAME, true);
      const taskFilters = getDataFromLocalStorage(TASK.FILTER_NAME);
      let momentId = taskFilters?.student_tracking__momenttracking__moment;

      if (!momentId && moments.length) {
        // get the first moment
        const itemsFiltered = moments.filter(
          (element) => element.name.toLowerCase() === "momento 1"
        );
        momentId = itemsFiltered.length ? itemsFiltered[0].id : moments[0].id;
      }
      if (momentId) {
        setSessionFilters(
          TASK.FILTER_NAME,
          {
            page: 1,
            page_size: Number(pageSize),
            moment: momentId,
            student_tracking__momenttracking__moment: momentId,
            student_tracking__momenttracking__state: "active",
            client_filters: null,
            source: "moment",
          },
          true
        );
        dispatch(markMoment(momentId));
        dispatch(fetchClientFilters({ moment_id: momentId, module: "tasks" }));
      }

      const finalParams = {
        moment: momentId ?? "",
        student_tracking__momenttracking__moment: momentId ?? "",
        student_tracking__momenttracking__state: "active",
      };

      dispatch(getTaskList({ ...queryParams, ...finalParams }));
    }
  };

  const renderLogo = () => {
    if (user && user.client_logo) {
      return (
        <img className={styles.clientLogo} src={user.client_logo} alt="logo" />
      );
    }
    return <h3>{user && user.client_area ? user.client_area : "Nimbi"}</h3>;
  };

  return (
    <nav id="sidebar" className={`${isOpened ? "" : "active"}`}>
      <div className="sidebar-col">
        <div className="sidebar-header">
          <div
            style={{ fontSize: 20 }}
            id="dismiss"
            onClick={onToggleSidebar}
            aria-hidden="true"
          >
            <FontAwesomeIcon
              className="text-light buttonStyle"
              icon={faChevronCircleLeft}
            />
          </div>
          <div className="ml-2">{renderLogo()}</div>
        </div>
        <div className="sidebar-content">
          <ProtectedBlock allowedCode="dashboard">
            <ul className="list-unstyled components">
              <Link
                className="active"
                to={`/${DASHBOARD.URL}`}
                onClick={() => closeSidebar()}
                aria-hidden="true"
              >
                {dashboardMenuTitle}
              </Link>
            </ul>
          </ProtectedBlock>
          <ProtectedBlock allowedCode="task_list">
            <ul className="list-unstyled components">
              <Link
                className="active"
                to={`/${TASK.URL}`}
                onClick={() => closeSidebar(TASK.URL)}
                aria-hidden="true"
              >
                {taskMenuTitle}
              </Link>
            </ul>
          </ProtectedBlock>
          <ul
            style={{ marginTop: 40 }}
            className="d-none list-unstyled components"
          >
            <p>
              Performance Modelos&nbsp;&nbsp;
              <FontAwesomeIcon
                style={{ fontSize: "0.7rem" }}
                className="text-light buttonStyle"
                icon={faExternalLinkAlt}
              />
            </p>
          </ul>
          <ul className="d-none list-unstyled components">
            <p>Efectividad</p>
          </ul>
          <ul className="d-none list-unstyled components">
            <p>Integridad Datos</p>
          </ul>
          <ul className="d-none list-unstyled components">
            <p>Comunicaciones</p>
          </ul>
          <ProtectedBlock allowedCode="student_list">
            <ul className="list-unstyled components">
              <Link
                to={`/${STUDENT.URL}`}
                onClick={closeSidebar}
                aria-hidden="true"
              >
                {studentMenuTitle}
              </Link>
            </ul>
          </ProtectedBlock>
          <ProtectedBlock allowedCode="student_list">
            <ul className="list-unstyled components">
              <Link
                to={`/${MOMENT.URL}`}
                onClick={closeSidebar}
                aria-hidden="true"
              >
                {momentMenuTitle}
              </Link>
            </ul>
          </ProtectedBlock>
          <ProtectedBlock allowedCode="report_list">
            <ul className="list-unstyled components">
              <Link
                to={`/${REPORT.URL}`}
                onClick={closeSidebar}
                aria-hidden="true"
              >
                {reportMenuTitle}
              </Link>
            </ul>
          </ProtectedBlock>
          <ProtectedBlock allowedCode="user_list">
            <ul className={`list-unstyled components`}>
              <Link
                className="active"
                to={`/${USER.URL}`}
                onClick={() => closeSidebar()}
                aria-hidden="true"
              >
                {userMenuTitle}
              </Link>
            </ul>
          </ProtectedBlock>
          <ul
            style={{ marginTop: 40 }}
            className="d-none list-unstyled components"
          >
            <p>
              <FontAwesomeIcon
                className="text-light buttonStyle"
                icon={faCog}
              />
              &nbsp;&nbsp;Templates
            </p>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NSidebar;
