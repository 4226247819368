import React, { useEffect, useCallback } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faUser } from "@fortawesome/pro-regular-svg-icons";

import { fetchUsers } from "store/actions/async/user-async.actions";
import {
  APP_MODULES,
  setSessionFilters,
  getDataFromLocalStorage,
  getExtraColumns,
} from "utils";
import { NTable } from "components/Nimbiv2";
import {
  CardNimbi,
  IconContainer,
  AnonymousContainer,
  NButtonCircle,
} from "components/Nimbiv2/styled";
import { Image, NBadge } from "components";
import { useTranslationLocal } from "hooks";
import styles from "../User.module.css";
import { ButtonSize, colorsBadge } from "utils/constants";
import moment from "moment";

const UserList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const {
    list: users,
    pending: { list: isPending },
    pagination,
  } = useSelector((state) => state.user);

  const { user } = useSelector((state) => state.auth);

  const new_columns_table = getExtraColumns("tableUser");

  useEffect(() => {
    const user_filters = getDataFromLocalStorage("user_filters", null);
    if (user_filters) {
      setSessionFilters(APP_MODULES.USER.FILTER_NAME, user_filters);
    } else {
      setSessionFilters(APP_MODULES.USER.FILTER_NAME, {
        page: 1,
        page_size: 10,
      });
    }
    dispatch(fetchUsers());
  }, [dispatch]);

  const onEdit = useCallback(
    (row) => {
      history.push(`/administracion/user/edit/${row.id}`);
    },
    [history]
  );

  const styleContainer = {
    border: "2.4px solid var(--secondary-color)",
    borderRadius: "50%",
  };

  const printUserIcon = (item) => {
    return user && item.profile_image && !user.anonymous ? (
      <div style={styleContainer}>
        <Image
          style={{ border: "2.4px solid white" }}
          className={`${styles.listUserThumb}`}
          src={item.profile_image}
          alt="photo"
          aria-hidden="true"
          roundedCircle
        />
      </div>
    ) : (
      <div style={styleContainer}>
        <IconContainer size="42px" style={{ border: "2.4px solid white" }}>
          <FontAwesomeIcon
            className="icons_color"
            icon={faUser}
            style={{ fontSize: "20px" }}
          />
        </IconContainer>
      </div>
    );
  };

  const columns = [
    {
      columnName: "user",
      title: translation("user", "", "USUARIO"),
      key: "user",
      fixed: true,
      style: { left: "0px" },
      width: "30%",
      render: (item) => {
        return (
          <div
            className={`d-flex justify-content-left align-items-center ${styles.firstColumn} ${styles.iconField}`}
            onClick={() => onEdit(item)}
          >
            {printUserIcon(item)}
            <div className="d-flex flex-column justify-content-left align-items-cente ml-2">
              <div
                className={`${styles.headerTitle} ${styles.iconField}`}
                style={{
                  fontWeight: 500,
                  letterSpacing: "0.3px",
                  marginLeft: "10px",
                }}
              >
                {item.first_name}{" "}
                <AnonymousContainer isAnonymous={user.anonymous}>
                  {item.last_name}
                </AnonymousContainer>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      columnName: "mail",
      title: translation("mail", "upper"),
      key: "mail",
      width: "20%",
      render: (item) => (
        <span className={`mr-1 capitalize-text ${styles.rowText}`}>
          <AnonymousContainer isAnonymous={user.anonymous}>
            <NBadge className={styles.badgeText} text={item.email} />
          </AnonymousContainer>
        </span>
      ),
    },
    {
      columnName: "profiles",
      title: translation("profiles", "upper"),
      key: "profiles",
      width: "15%",
      render: (item) => (
        <span className={`mr-1 capitalize-text ${styles.rowText}`}>
          <AnonymousContainer isAnonymous={user.anonymous}>
            {item.groups?.length > 0
              ? item.groups.map((group) => group.name).join(", ")
              : t("no_information_line")}
          </AnonymousContainer>
        </span>
      ),
    },
    {
      columnName: "teams",
      title: translation("teams", ""),
      key: "teams",
      width: "20%",
      render: (item) => (
        <span className={`mr-1 capitalize-text ${styles.rowText}`}>
          {item.teams
            ? item.teams.length > 0
              ? item.teams.join(", ")
              : t("no_information_line")
            : t("no_information_line")}
        </span>
      ),
    },
    {
      columnName: "last_login",
      title: translation("last_login", "upper"),
      key: "state",
      width: "10%",
      render: (item) => (
        <span>
          {item?.last_login
            ? moment(item?.last_login).format("DD/MM/YYYY - HH:mm")
            : "---"}
        </span>
      ),
    },
    {
      columnName: "state",
      title: translation("state", "upper"),
      key: "state",
      width: "5%",
      render: (item) => (
        <span className={`mr-1 ${styles.rowText}`}>
          <UserStatus isActive={item.is_active} />
        </span>
      ),
    },
    ...getExtraColumns("tableUser"),
    {
      columnName: "action",
      title: translation("action", "upper"),
      key: "mail",
      width: "10%",
      fixed: true,
      style: { right: "0px" },
      render: (item) => (
        <NButtonCircle
          sizeStyle={ButtonSize.lg}
          onClick={() => onEdit(item)}
          icon={<FontAwesomeIcon icon={faPen} style={{ marginTop: "5px" }} />}
        />
      ),
    },
  ];

  return (
    <Container fluid>
      {pagination && (
        <div style={{ fontSize: "var(--font-size-small)", marginTop: "-18px" }}>
          <strong>{pagination.count}</strong> {t("users_found")}
        </div>
      )}
      <CardNimbi padding="0px 16px 16px 16px" style={{ marginTop: "11.5px" }}>
        <NTable
          tableName="tableUser"
          widthSkeletons="100%"
          columns={columns}
          dataSource={users}
          check={false}
          upperCaseHeader
          isPending={isPending}
          textEmpty="No hay usuarios para mostrar"
          fixed={new_columns_table.length > 0}
        ></NTable>
      </CardNimbi>
    </Container>
  );
};
const UserStatus = React.memo(({ isActive = true }) => {
  const { t } = useTranslation();
  return (
    <NBadge
      customStyle={{ padding: "3.5px 6px", borderRadius: "64px" }}
      newColor={
        isActive ? colorsBadge.light.success : colorsBadge.light.inactive
      }
      text={isActive ? _.upperFirst(t("active")) : _.upperFirst(t("inactive"))}
    ></NBadge>
  );
});

export default UserList;
