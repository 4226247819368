import React, { useEffect } from "react";
import _ from "lodash";
import { NTable } from "components/Nimbiv2";
import { CardNimbi } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { fetchSurveyPeriods } from "store/actions/async/crm-async.action";
import { fetchStudentAnswer } from "store/actions/async/student-async.actions";


const SurveyPage = ({ student_tracking_id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    surveyAnwers,
    pending: { surveyAnwers: isPending },
  } = useSelector((state) => state.student);
  const {
    surveyPeriods,
    pending: { surveyPeriods: isPendingPeriods },
  } = useSelector((state) => state.crm);

  useEffect(() => {
    // call endpoint
    dispatch(fetchStudentAnswer(student_tracking_id));
    dispatch(fetchSurveyPeriods(student_tracking_id));
  }, [dispatch, student_tracking_id]);

  let isSelected = false;

  const callStudentAnswer = (survey_id) => {
    if (!isPendingPeriods) {
      if (survey_id) {
        dispatch(fetchStudentAnswer(student_tracking_id, { survey_id: survey_id }));
        isSelected = true;
      }
      else {
        dispatch(fetchStudentAnswer(student_tracking_id));
        isSelected = false;
      }
    }
  };

  const columns = [
    {
      title: _.upperFirst(t("questions")),
      key: "question",
      dataIndex: "question",
      fixed: false,
      style: { left: 0, width: "40%", display: "inline-flex", verticalAlign: "baseline", flexDirection: "column" },
      render: (item, key) => (
        <div>
          {item}
        </div>
      ),
    },
    {
      title: _.upperFirst(t("answers")),
      key: "answer",
      dataIndex: "answer",
      fixed: false,
      style: { left: 0, width: "60%", display: "inline-flex", verticalAlign: "baseline", flexDirection: "column"  },
      render: (item, key) => (
        <div>
          {item}
        </div>
      ),
    },
  ];
  return (
    <div>
      {surveyPeriods && surveyPeriods.length > 0 && (
        <div>
          <ContainerSelect style={{ marginBottom: "16px" }}>
            <Form.Control
              as="select"
              onChange={(event) => {
                callStudentAnswer(event.target.value);
              }}
            >
              <option value={""}>{t("select_a_survey")}</option>
              {surveyPeriods.map((item, key) => {
                return (
                  <option key={item.id} value={item.id}>
                    {`${item.period} - ${item.display_name}`}
                  </option>
                );
              })}
            </Form.Control>
          </ContainerSelect>
        </div>
      )}
      <CardNimbi
        radius="8px"
        padding="23px 26px"
        style={{ height: "fit-content" }}
      >
        <NTable
          columns={columns}
          dataSource={ surveyAnwers ?? []}
          fixed={false}
          isPending={isPending}
          collapse={false}
          textEmpty={ isSelected ? t("survey_no_answered") : t("select_a_survey")}
        ></NTable>
      </CardNimbi>
    </div>
  );
};

export default SurveyPage;
const ContainerSelect = styled.div`
  .form-control {
    background: transparent;
    padding: 14px 12px;
    height: auto;
    width: auto;
  }
`;
