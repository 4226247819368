/* eslint-disable no-restricted-syntax */
import Actions from "./permissions";

const actions = Actions();

const directorPermissions = () => {
  return actions.excludeActions([
    "student_apply_actions",
    "user_list",
    "initiatives_list",
    "initiatives_tasks",
  ]);
};

const subdirectorPermissions = () => {
  return actions.excludeActions([
    "student_apply_actions",
    "user_list",
    "initiatives_list",
    "initiatives_tasks",
  ]);
};

const retencionPermissions = () => {
  return actions.excludeActions([
    "user_list",
    "initiatives_list",
    "initiatives_tasks",
  ]);
};

const supervisorPermissions = () => {
  return actions.excludeActions(["user_list"]);
};

export const USER_GROUPS = {
  ADMINISTRATOR: {
    ROLE: "Administrator",
    ROLE_NAME: "Administrador",
    PERMISSIONS: actions.baseActions,
    ACTIONS_PERMISSION: actions.actionsPermission,
  },
  COORDINATOR: {
    ROLE: "coordinador",
    ROLE_NAME: "Coordinador",
    PERMISSIONS: [
      "dashboard",
      "moment_list",
      "task_list",
      "student_list",
      "student_search",
      "help_list",
    ],
    ACTIONS_PERMISSION: [],
  },
  DIRECTOR: {
    ROLE: "director_academico",
    ROLE_NAME: "Director Académico",
    PERMISSIONS: directorPermissions(),
    ACTIONS_PERMISSION: [],
  },
  HEAD_SCHOOL: {
    ROLE: "jefe_de_escuela",
    ROLE_NAME: "Jefe de Escuela",
    PERMISSIONS: [
      "dashboard",
      "moment_list",
      "task_list",
      "student_list",
      "student_search",
      "help_list",
    ],
    ACTIONS_PERMISSION: [],
  },
  RETENCION: {
    ROLE: "retencion",
    ROLE_NAME: "Retención",
    PERMISSIONS: retencionPermissions(),
    ACTIONS_PERMISSION: [],
  },
  SUBDIRECTOR: {
    ROLE: "subdirector_academico",
    ROLE_NAME: "Subdirector Académico",
    PERMISSIONS: subdirectorPermissions(),
    ACTIONS_PERMISSION: [],
  },
  SUPERVISOR: {
    ROLE: "Supervisor",
    ROLE_NAME: "Supervisor",
    PERMISSIONS: supervisorPermissions(),
    ACTIONS_PERMISSION: actions.actionsPermission,
  },
  EXECUTIVE: {
    ROLE: "Executive",
    ROLE_NAME: "Ejecutivo",
    PERMISSIONS: [
      "dashboard",
      "moment_list",
      "task_list",
      "student_list",
      "student_search",
      "help_list",
      "initiatives_tasks",
    ],
    ACTIONS_PERMISSION: actions.actionsPermission,
  },
  REPORTER: {
    ROLE: "Reporter",
    ROLE_NAME: "Analista",
    PERMISSIONS: ["report_list", "help_list"],
    ACTIONS_PERMISSION: [],
  },
  VIEWER: {
    ROLE: "Viewer",
    ROLE_NAME: "Analista",
    PERMISSIONS: ["student_list"],
    ACTIONS_PERMISSION: [],
  },
};

export const SUPER_ADMIN_GROUPS = () =>
  Object.values(USER_GROUPS).map((group) =>
    group.ROLE === "Administrator" || group.ROLE === "Reporter"
      ? "Normal"
      : group.ROLE
  );

export const checkAccess = ({ userRoles, allowedRoles }) => {
  // checks whether the user has access to the page or not
  // returns a boolean
  if (allowedRoles.length === 0) return true;

  return allowedRoles.some((item) => userRoles.includes(item));
};

export const permissionChecker = ({ allowedCode, userRoles }) => {
  // checks whether the user has access to the block/section or not
  // returns a boolean
  if (!allowedCode) return true;
  if (userRoles.length === 0) return false;

  let hasPermission = false;

  for (const role of userRoles) {
    const roleFound = Object.entries(USER_GROUPS).find(
      (item) => item[1].ROLE === role
    );
    const permissions =
      roleFound && typeof roleFound !== "undefined"
        ? roleFound[1].PERMISSIONS
        : [];
    const isValidPermission = permissions.some((code) => code === allowedCode);
    hasPermission = isValidPermission;

    if (isValidPermission) {
      return hasPermission;
    }
  }

  return hasPermission;
};

export const permissionRedirect = ({ userRoles }) => {
  const urls = {
    task_list: "/tareas",
    student_list: "/alumnos",
    report_list: "/reportes",
    user_list: "/user",
    administrator: "/administracion",
  };
  let permissions = [];

  for (const role of userRoles) {
    const roleFound = Object.entries(USER_GROUPS).find(
      (item) => item[1].ROLE === role
    );
    permissions = permissions.concat(roleFound ? roleFound[1].PERMISSIONS : []);
  }

  if (permissions.includes("dashboard")) {
    return "/dashboard";
  }
  let permissions_list = permissions.filter((item) => item.includes("_list"));
  return urls[permissions_list[0]];
};
