import { generateUrlWithParams } from "utils";
import api from "services/api";
import logger from "services/logService";

export const getInitiatives = async (queryParams) => {
  const finalUrl = generateUrlWithParams(
    "initiatives/initiative/",
    queryParams
  );

  try {
    const response = await api.get(finalUrl);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

export const createInitiative = async (queryParams) => {
  const finalUrl = "initiatives/initiative/";
  const config = {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type":
        queryParams.source_type === "external"
          ? "multipart/form-data"
          : "application/json",
    },
  };
  try {
    const response = await api.post(finalUrl, queryParams, config);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return Object.fromEntries(err);
  }
};

export const updateInitiative = async (id, queryParams, edit = false) => {
  const finalUrl = `initiatives/initiative/${id}/`;
  const config = {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type":
        queryParams.source_type === "external" && !edit
          ? "multipart/form-data"
          : "application/json",
    },
  };
  try {
    const response = await api.patch(finalUrl, queryParams, config);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

export const getTypesInitiative = async (queryParams) => {
  const finalUrl = generateUrlWithParams(
    "initiatives/initiative-type/",
    queryParams
  );

  try {
    const response = await api.get(finalUrl);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

export const getStudentsTracking = (queryParams, dataPost = null) => {
  const finalUrl = generateUrlWithParams(
    "initiatives/student-tracking/",
    queryParams
  );
  if (dataPost) {
    return api.post(finalUrl, dataPost);
  }
  return api.get(finalUrl);
};

export const getInitiative = (id, queryParams) => {
  const finalUrl = generateUrlWithParams(
    `initiatives/initiative/${id}/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const getInitiativeStudentsTracking = (id, queryParams) => {
  const finalUrl = generateUrlWithParams(
    `initiatives/student-tracking/${id}/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const updateInitiativeStatus = async (id, status) => {
  const finalUrl = `initiatives/initiative/${id}/updateStatus/`;
  try {
    const response = await api.patch(finalUrl, { status: status });
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

export const getInitiativesCall = () => {
  const finalUrl = `initiatives/initiative/initiative_call_active/`;
  return api.get(finalUrl);
};

export const getInitiativeInsights = (id, queryParams) => {
  const finalUrl = generateUrlWithParams(
    `initiatives/initiative/${id}/insights/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const getInitiativeListStudentTracking = (id) => {
  const finalUrl = `initiatives/initiative/${id}/listStudentTracking/`;
  return api.get(finalUrl);
};

export const updateInitiativeEndDate = async (id, data) => {
  const finalUrl = `initiatives/initiative/${id}/updateEndDate/`;
  try {
    const response = await api.patch(finalUrl, data);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

export const getQuestionsInitiative = async (queryParams) => {
  const finalUrl = generateUrlWithParams(
    "call_forms/questions/initiative/",
    queryParams
  );

  try {
    const response = await api.get(finalUrl);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

export const getStudentResponsesInitiative = async (queryParams) => {
  const finalUrl = generateUrlWithParams(
    "initiatives/student-responses/",
    queryParams
  );

  try {
    const response = await api.get(finalUrl);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

export const relaunchInitiative = async (id, payload) => {
  const finalUrl = `initiatives/initiative/${id}/relaunch/`;
  try {
    const response = await api.post(finalUrl, payload);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};
