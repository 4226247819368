import React, { useEffect, useCallback, useRef, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { NScriptTemplate } from "components";
import PusherContextProvider from "store/pusher";
import {
  fetchCampus,
  // fetchUsedTags,
  fetchTemplates,
  fetchListCampus,
} from "store/actions/async/crm-async.action";
import {
  fetchRoles,
  fetchClientAreas,
} from "store/actions/async/site-async.action";
import {
  applyThemeColors,
  deleteLocalStorage,
  getDataFromLocalStorage,
  //  setDataToLocalStorage,
  typesTaskEvent,
} from "utils";
import "./sidebar-styles.css";
// import { dispatchTaskEvent } from "services/crmService";
import { fetchTeams } from "store/actions/async/team-async.actions";
import {
  fetchProcessMulTaskRequest,
  fetchProcessTaskRequest,
} from "store/actions/async/task-async.action";
import { cleanTask } from "store/actions/task.actions";
import { NErrorBoundary } from "components/Nimbiv2";

const MainContent = ({ children }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    campus,
    // usedTags,
    emailTemplates,
    callTemplates,
    whatsappTemplate,
    listCampus,
  } = useSelector((state) => state.crm.singleLoads);
  const { i18n } = useTranslation();
  const { roles } = useSelector((state) => state.site.singleLoads);
  const { teams } = useSelector((state) => state.team);
  const { clientAreas } = useSelector((state) => state.site.singleLoads);
  const isOpened = useSelector((state) => state.site.isCollapsedOpen);
  const session = useSelector((state) => state.auth.user);
  const cache = useRef({
    studentResponses: false,
    callResults: false,
    // usedTags: false,
    roles: false,
    clientAreas: false,
    teams: false,
  });

  const loadCampus = useCallback(() => {
    if (session && !campus && !cache.current.campus) {
      cache.current.campus = true;
      dispatch(fetchCampus());
    }
  }, [dispatch, session, campus]);

  const loadListCampus = useCallback(() => {
    if (session && !listCampus && !cache.current.listCampus) {
      cache.current.listCampus = true;
      dispatch(fetchListCampus());
    }
  }, [dispatch, session, listCampus]);

  // deprecated code
  // const loadUsedTags = useCallback(() => {
  //   if (session && !usedTags && !cache.current.usedTags) {
  //     cache.current.usedTags = true;
  //     dispatch(fetchUsedTags());
  //   }
  // }, [dispatch, session, usedTags]);

  const loadEmailTemplates = useCallback(() => {
    if (session && !emailTemplates && !cache.current.emailTemplates) {
      cache.current.emailTemplates = true;
      dispatch(fetchTemplates("email"));
    }
  }, [dispatch, session, emailTemplates]);

  const loadCallTemplates = useCallback(() => {
    if (session && !callTemplates && !cache.current.callTemplates) {
      cache.current.callTemplates = true;
      dispatch(fetchTemplates("call"));
    }
  }, [dispatch, session, callTemplates]);

  const loadWhatsappTemplate = useCallback(() => {
    if (session && !whatsappTemplate && !cache.current.whatsappTemplate) {
      cache.current.whatsappTemplate = true;
      dispatch(fetchTemplates("whatsapp"));
    }
  }, [dispatch, session, whatsappTemplate]);

  const loadRoles = useCallback(() => {
    if (
      session &&
      !roles &&
      !cache.current.roles &&
      session.groups.includes("Administrator")
    ) {
      cache.current.roles = true;
      dispatch(fetchRoles());
    }
  }, [dispatch, session, roles]);

  const loadClientAreas = useCallback(() => {
    if (
      session &&
      !clientAreas &&
      !cache.current.clientAreas &&
      session.groups.includes("Administrator")
    ) {
      cache.current.clientAreas = true;
      dispatch(fetchClientAreas());
    }
  }, [dispatch, session, clientAreas]);

  const loadTeams = useCallback(() => {
    if (
      session &&
      !teams &&
      !cache.current.teams &&
      session.groups.includes("Administrator")
    ) {
      cache.current.teams = true;
      dispatch(fetchTeams());
    }
  }, [dispatch, session, teams]);

  useEffect(() => {
    // load all data once.
    loadCampus();
    // loadUsedTags();
    loadEmailTemplates();
    loadCallTemplates();
    loadWhatsappTemplate();
    loadRoles();
    loadClientAreas();
    loadListCampus();
    loadTeams();
  }, [
    loadCampus,
    // loadUsedTags,
    loadEmailTemplates,
    loadCallTemplates,
    loadRoles,
    loadClientAreas,
    loadListCampus,
    loadTeams,
    loadWhatsappTemplate,
  ]);

  const palette = useMemo(() => {
    if (!session) return null;
    const { client_color_palette: colorPalette = null } = session;
    return colorPalette;
  }, [session]);

  applyThemeColors(palette);

  const location = useLocation();

  useEffect(() => {
    let pathname = location.pathname;
    let array = pathname.split("/").filter((item) => item !== "");
    if (array[0] === "tareas" && array.length > 1) {
      // setDataToLocalStorage("taskSelect", { id: id });
    } else {
      const data = getDataFromLocalStorage("taskSelect");
      const dataGroup = getDataFromLocalStorage("groupTask");
      if (data !== null) {
        dispatch(fetchProcessTaskRequest(data, typesTaskEvent.RELEASED));
        dispatch(cleanTask());
      }
      if (dataGroup && dataGroup !== null) {
        dispatch(
          fetchProcessMulTaskRequest(dataGroup, typesTaskEvent.RELEASED)
        );
        dispatch(cleanTask());
      }
      deleteLocalStorage("taskSelect");
      deleteLocalStorage("groupTask");
    }
  }, [id, location, dispatch]); // react-hooks/exhaustive-deps

  useEffect(() => {
    if (session) {
      i18n.changeLanguage(session.user_language);
    }
  }, [session, i18n]);

  return (
    <NErrorBoundary>
      <div
        className={!isOpened ? "wrapper" : "wrapperCloseSider"}
        style={session ? {} : { width: "100%" }}
      >
        {/* Script calling modal */}
        <nav id="navscript" className="">
          <NScriptTemplate />
        </nav>

        <div id="content">
          <PusherContextProvider>{children}</PusherContextProvider>
        </div>
      </div>
    </NErrorBoundary>
  );
};

export default MainContent;
