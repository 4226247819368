import React from "react";
import _ from "lodash";
import { validateActionsPermissions } from "utils/user";
import { useSelector } from "react-redux";

const NBlockedAction = ({ children, permissions }) => {
  const { user } = useSelector((state) => state.auth, _.isEqual);

  if (!validateActionsPermissions(user, permissions)) {
    return null;
  }
  return <>{children}</>;
};

export default NBlockedAction;
