import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { Col, NSidebarContainer } from "components";
import { useTranslation } from "react-i18next";
import { RowItem } from "components/Nimbiv2/styled";
import { NDatePickerIni, NButton } from "components/Nimbiv2";
import {
  faEnvelope,
  faFile,
  faHouse,
  faPhone,
} from "@fortawesome/pro-regular-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { relaunchInitiative } from "services/initiativeService";
import { Formik } from "formik";
import IniciativeModal from "components/Nimbiv2/IniciativeModal";
import { toast } from "react-toastify";
import styled from "styled-components";

const FormRelaunchCampaign = ({
  open,
  student_tracking_id,
  onClose,
  studentID,
  saveLastValue = () => {},
  lastData = null,
  initiative,
}) => {
  const dateInitial = new Date();
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [objectCreate, setObjectCreate] = useState(null);
  const [show, setShow] = useState(false);
  const [textModal, setTextModal] = useState({
    title: "¡Tu campaña fue creada con éxito!",
    description:
      "Puedes revisarla en la sección de campañas y hacer seguimiento al avance de tus gestiones.",
  });
  const formRef = useRef(null);
  const initiativeTypes = {
    Call: {
      template: "script_template",
      icon: faPhone,
      name: t("call"),
    },
    Email: {
      template: "email_template",
      icon: faEnvelope,
      name: t("email"),
    },
    WhatsApp: {
      template: "whatsapp_template",
      icon: faWhatsapp,
      name: t("whatsapp"),
    },
  };

  useEffect(() => {
    setTextModal({
      title: t("initiative_saved_sucessfully"),
      description: t("check_detail_initiativa"),
    });
  }, [t]);

  const saveCloneInitiative = useCallback(
    async (values) => {
      setLoading(true);
      const { data, status } = await relaunchInitiative(initiative.pk, {
        student_trackings: initiative.not_called,
        start_date:
          moment(values["start_date"]).format("YYYY-MM-DD") + " 12:00:00",
        end_date: moment(values["end_date"]).format("YYYY-MM-DD") + " 12:00:00",
      });
      if (status === 200) {
        setObjectCreate(data);
        setShow(true);
      } else {
        toast.error("Error en la acción solicitada");
      }
      setLoading(false);
    },
    [initiative]
  );

  const validate = (values) => {
    const errors = {};
    if (!values.start_date) {
      errors.start_date = t("selection_required");
    }
    if (!values.end_date) {
      errors.end_date = t("selection_required");
    }

    if (values.start_date && values.end_date) {
      let start_date = moment(values.start_date).format("YYYY-MM-DD");
      let end_date = moment(values.end_date).format("YYYY-MM-DD");
      if (start_date === end_date) {
        errors.start_date = "No pueden ser las mismas fechas";
        errors.end_date = "No pueden ser las mismas fechas";
      }
    }

    return errors;
  };

  return (
    <NSidebarContainer
      width="634px"
      openSide={open}
      title={"Relanzar campaña"}
      onClose={() => {
        onClose();
        formRef.current.resetForm();
      }}
    >
      <Formik
        initialValues={{}}
        validate={validate}
        onSubmit={saveCloneInitiative}
        innerRef={(f) => (formRef.current = f)}
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          values,
          resetForm,
        }) => (
          <div
            style={{ height: "100%", flexDirection: "column" }}
            className="d-flex"
          >
            <div style={{ height: "100%" }}>
              <div>
                Relanza tu campaña fácilmente: dirige un nuevo intento solo a
                los estudiantes que no respondieron y fija una nueva fecha.{" "}
              </div>
              <div style={{ marginTop: "48px" }}>
                <div style={{ fontSize: "18px", fontWeight: "600" }}>
                  Selecciona las nuevas fechas
                </div>

                {open && (
                  <div style={{ marginBottom: "24px", marginTop: "16px" }}>
                    <RowItem>
                      <Col xs={6} style={{ paddingLeft: "0px" }}>
                        <NDatePickerIniContainer>
                          <NDatePickerIni
                            label={t("start_date")}
                            onChange={(date) => {
                              setFieldValue("start_date", date);
                            }}
                            minDate={dateInitial}
                            errorText={errors["start_date"] ?? ""}
                          />
                        </NDatePickerIniContainer>
                      </Col>
                      <Col xs={6} style={{ paddingRight: "0px" }}>
                        <NDatePickerIniContainer>
                          <NDatePickerIni
                            label={t("end_date")}
                            onChange={(date) => {
                              setFieldValue("end_date", date);
                            }}
                            minDate={dateInitial}
                            errorText={errors["end_date"] ?? ""}
                          />
                        </NDatePickerIniContainer>
                      </Col>
                    </RowItem>
                  </div>
                )}

                <div style={{ marginTop: "40px" }}>
                  <div style={{ fontSize: "18px", fontWeight: "600" }}>
                    Información de la campaña
                  </div>
                  <div
                    style={{
                      padding: "16px",
                      background: "#F6FAFE",
                      marginTop: "16px",
                    }}
                  >
                    <RowItem>
                      <Col xs="6" style={{ paddingLeft: "0px" }}>
                        <strong>Nombre</strong>
                        <div>{initiative?.name}</div>
                      </Col>
                      <Col xs="3">
                        <strong>Tipo</strong>
                        {initiative && (
                          <div>
                            <FontAwesomeIcon
                              icon={
                                initiativeTypes[
                                  initiative?.initiative_type?.name
                                ]?.icon
                              }
                              style={{ marginRight: "8px" }}
                            />
                            {
                              initiativeTypes[initiative?.initiative_type?.name]
                                ?.name
                            }
                          </div>
                        )}
                      </Col>
                      <Col xs="3" style={{ paddingRight: "0px" }}>
                        <strong>Base datos</strong>

                        <div>
                          <FontAwesomeIcon
                            icon={
                              initiative?.source_type === "internal"
                                ? faHouse
                                : faFile
                            }
                            style={{ marginRight: "8px" }}
                          />
                          {_.upperFirst(t(initiative?.source_type + "_detail"))}{" "}
                          {initiative?.source_type === "internal"
                            ? "Nimbi"
                            : ""}
                        </div>
                      </Col>
                    </RowItem>

                    <div style={{ marginTop: "24px" }}>
                      <strong> {_.upperFirst(t("objetive"))}</strong>
                      <div>{initiative?.objective}</div>
                    </div>
                    <div style={{ marginTop: "24px" }}>
                      <strong>Alumnos seleccionados</strong>
                      <div>{initiative?.not_called?.length} alumnos</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <RowItem className={`pt-4`}>
              <Col xs="4">
                <NButton
                  disabled={loading}
                  outlined
                  transparent
                  onClick={() => {
                    onClose();
                    resetForm();
                  }}
                  style={{ width: "100%" }}
                >
                  {_.upperFirst(t("cancel"))}
                </NButton>
              </Col>
              <Col xs="8">
                <NButton
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {"Relanzar campaña"}
                </NButton>
              </Col>
            </RowItem>
          </div>
        )}
      </Formik>
      <IniciativeModal
        show={show}
        handleClose={() => {
          setShow(false);
        }}
        buttonAction={() => {
          onClose();
          setShow(false);
          history.push(`/campañas/${objectCreate?.pk}`);
        }}
        textTitle={textModal.title}
        description={textModal.description}
      />
    </NSidebarContainer>
  );
};
export default FormRelaunchCampaign;

const NDatePickerIniContainer = styled.div`
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
`;
