import { useDispatch, useSelector } from "react-redux";
import { APP_MODULES, setSessionFilters } from "utils";
import { fetchStudents, fetchStudentsByTag } from "store/actions/async/student-async.actions";
import { getTaskList } from "store/actions/async/crm-async.action";
import { toggleSTagStatus } from "store/actions/student.actions";
import { toggleTagStatus } from "store/actions/crm.actions";

const { TASK, STUDENT } = APP_MODULES;

const useTagData = ({ module }) => {
  const dispatch = useDispatch();

  const { usedTags } = useSelector((state) => state.crm);
  const { studentTags } = useSelector((state) => state.student);

  const filterTasks = (selectedTag) => {
    const { tag, selected } = selectedTag;
    const searchTag = !selected ? tag : null;

    setSessionFilters(TASK.FILTER_NAME, { page: 1, tag: searchTag });
    dispatch(toggleTagStatus({ tagName: tag, sw: !selected }));
    dispatch(getTaskList());
  };

  const filterStudents = (selectedTag) => {
    const { id, tag, selected } = selectedTag;
    const searchTag = !selected ? tag : null;
    const filter = searchTag ? { page: 1, tag: searchTag } : { page: 1 };

    setSessionFilters(STUDENT.FILTER_NAME, filter);
    dispatch(toggleSTagStatus({ tagName: tag, sw: !selected }));

    if (searchTag) {
      dispatch(fetchStudentsByTag(id));
    } else {
      dispatch(fetchStudents());
    }
  };

  switch (module) {
    case TASK.URL:
      return [usedTags, filterTasks];

    case STUDENT.URL:
      return [studentTags, filterStudents];

    default:
      return [[], () => {}];
  }
};

export default useTagData;
