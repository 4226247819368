import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Col, NSidebarContainer } from "components";
import { setVisibleGroup } from "store/actions/crm.actions";
import { NButton, NTable } from "components/Nimbiv2";
import { dispatchMultiTaskEvent } from "services/crmService";
import { useTranslation } from "react-i18next";
import { RowItem } from "components/Nimbiv2/styled";
import {
  getDataFromLocalStorage,
  setDataToLocalStorage,
  typesTaskEvent,
} from "utils";
import { useHistory } from "react-router-dom";

const TaskGroupSide = ({
  itemSelected,
  columns,
  showEmptyWidget,
  extras,
  oClose = () => {},
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [itemSelectedGroup, setItemSelectedGroup] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const user = getDataFromLocalStorage("user", {});
  const {
    pending: { groupedTasks: isPendingGroup },
    groupedTasks,
  } = useSelector((state) => state.crm, _.isEqual);

  const processMultiTaskRequest = async (rows, action) => {
    const payload = {
      action,
      channel: process.env.REACT_APP_PUSHER_CHANNEL,
      event: process.env.REACT_APP_PUSHER_EVENT,
      tasks: rows,
    };

    try {
      const {
        data: { result: response },
      } = await dispatchMultiTaskEvent(payload);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.resolve(false);
    }
  };

  useEffect(() => {
    if (itemSelectedGroup && itemSelectedGroup?.length === 1) {
      if (itemSelectedGroup[0] === 0) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    } else {
      setSelectAll(false);
    }
  }, [itemSelectedGroup, groupedTasks]);

  return (
    <NSidebarContainer
      title={`Tareas ${itemSelected?.student_tracking?.full_name}`}
      openSide={itemSelected ? true : false}
      onClose={() => {
        dispatch(setVisibleGroup(false));
        oClose();
      }}
      width="50%"
    >
      <div
        style={{ height: "100%", flexDirection: "column" }}
        className="d-flex"
      >
        <div style={{ height: "100%" }}>
          <div>{t("select_task_group")}</div>

          <NTable
            check={
              user["config"] &&
              user["config"]["call_task_version"] &&
              user["config"]["call_task_version"] === "version2"
            }
            keyName="table_group"
            widthSkeletons="100%"
            key={"table-task"}
            columns={columns?.filter((item) => item?.columnName !== "action")}
            dataSource={groupedTasks}
            upperCaseHeader
            isPending={isPendingGroup}
            collapse
            textEmpty={`No hay tareas para mostrar`}
            permissions={extras["tableTask"] ?? []}
            fixed={true}
            showEmptyWidget={showEmptyWidget}
            customStyle={{ backgroundColor: "white" }}
            selectAll={selectAll}
            onChange={(data) => {
              setItemSelectedGroup(data);
            }}
            hasSelect={itemSelectedGroup && itemSelectedGroup?.length > 0}
          />
        </div>
        {user["config"] &&
          user["config"]["call_task_version"] &&
          user["config"]["call_task_version"] === "version2" && (
            <RowItem className={`pt-4`}>
              <Col className="d-flex justify-content-end">
                <NButton
                  outlined
                  onClick={() => {
                    dispatch(setVisibleGroup(false));
                    oClose();
                  }}
                  disabled={loading}
                  style={{ padding: "10px 44px", borderRadius: "24px" }}
                >
                  {_.upperFirst(t("cancel"))}
                </NButton>
                <NButton
                  type="submit"
                  onClick={async () => {
                    if (itemSelectedGroup?.length > 0) {
                      let select = itemSelectedGroup;
                      if (itemSelectedGroup[0] === 0) {
                        select = groupedTasks?.map((item) => item.id);
                      }
                      setLoading(true);
                      setTimeout(async () => {
                        await processMultiTaskRequest(
                          select,
                          typesTaskEvent.TAKEN
                        );
                        setDataToLocalStorage("groupTask", select.join(","));

                        history.push(
                          `/tareas/${select[0]}/?groupTask=${select.join(",")}`
                        );
                        setLoading(false);
                      }, 500); // wait for 500ms to sh
                    } else {
                    }
                  }}
                  disabled={
                    loading ||
                    !(itemSelectedGroup && itemSelectedGroup?.length > 0)
                  }
                  style={{
                    height: "100%",
                    marginLeft: "16px",
                    padding: "10px 60px",
                    borderRadius: "24px",
                  }}
                  loading={loading}
                >
                  {_.upperFirst(t("manage"))}
                </NButton>
              </Col>
            </RowItem>
          )}
      </div>
    </NSidebarContainer>
  );
};
export default TaskGroupSide;
