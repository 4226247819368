/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Highlighter } from "react-bootstrap-typeahead";
import NInputFilter from "../NInputFilter";

import styles from "./NSearchCampus.module.css";

const filterFields = [];

const NSearchSelect = ({
  onChange,
  fnCallEndpoint,
  dataset = [],
  isAsync = false,
  isMultiple = false,
  itemsSelected = [],
  placeholder = "",
  orderFields = [],
  label = "name",
  showIcon = false,
  disabled = false,
  clear = 0,
  itemObject = true,
  filterIDSelected = false,
  objectAll,
}) => {
  const [selectedItem, setSelectedItem] = useState([]);

  useEffect(() => {
    if (itemsSelected.length > 0 && !filterIDSelected) {
      setSelectedItem(itemsSelected);
    }
    if (itemsSelected.length > 0 && filterIDSelected && dataset?.length > 0) {
      if (objectAll && itemsSelected?.length === dataset?.length - 1) {
        setSelectedItem([objectAll]);
      } else {
        let listSelect = dataset.filter((item) => {
          return itemsSelected?.includes(item.id);
        });

        setSelectedItem(listSelect);
      }
    }
  }, [itemsSelected, filterIDSelected, dataset, objectAll]);

  useEffect(() => {
    setSelectedItem([]);
  }, [clear]);

  const handleFilterChanged = (data) => {
    const [selected = {}] = data;

    setSelectedItem(data);
    if (isMultiple) {
      onChange(data);
    } else {
      onChange(selected);
    }
  };

  const customMenuItem = (option, { text }) => (
    <div className={styles.filterBody}>
      <div>
        <Highlighter search={text}>{option[label]}</Highlighter>
      </div>
    </div>
  );
  const customMenu = (option, { text }) => {
    return (
      <div className={styles.filterBody}>
        <div>
          <Highlighter search={text}>{option}</Highlighter>
        </div>
      </div>
    );
  };

  const asyncSearch = async (query) => {
    const isNumber = !isNaN(query);
    const params = {
      page_size: 10,
      search: query,
      ordering: isNumber ? orderFields.join() : orderFields.reverse().join(),
    };
    const {
      data: { results: matchedData },
    } = await fnCallEndpoint(params);
    return Promise.resolve(matchedData);
  };

  return (
    <NInputFilter
      handleChange={handleFilterChanged}
      data={dataset}
      compositeLabel={label ? [label] : []}
      placeholder={placeholder}
      selected={selectedItem}
      filterBy={filterFields}
      renderMenuItem={itemObject ? customMenuItem : customMenu}
      asyncSearch={asyncSearch}
      isAsync={isAsync}
      isMultiple={isMultiple}
      showIcon={showIcon}
      isDisabled={disabled}
    />
  );
};

NSearchSelect.propTypes = {
  //label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dataset: PropTypes.arrayOf(PropTypes.object),
  isAsync: PropTypes.bool,
  fnCallEndpoint: PropTypes.func,
};

export default NSearchSelect;
