import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LoginComponents } from "containers";
import { permissionRedirect } from "../../routes/helpers";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msal, googleAuth, isLoginMSAL, isLoginGoogle } from "utils";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { NNavbar } from "components/Nimbiv2";
import { getConfigPage } from "services/site.service";
import { isLoginSSO } from "utils";

const LoginSSO = () => {
  const history = useHistory();
  const session = useSelector((state) => state.auth.user);
  const msalInstance = new PublicClientApplication(msal.config);
  const [loginActive, setLoginActive] = useState(false);
  const getConfig = async () => {
    const { data } = await getConfigPage();
    if (data?.custom_login === false) {
      setLoginActive(false);
    } else {
      setLoginActive(true);
    }
  };
  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    if (!isLoginSSO()) {
      history.replace({
        pathname: "/",
      });
    }
  }, [history]);

  useEffect(() => {
    if (session) {
      history.replace({
        pathname: permissionRedirect({
          userRoles: session ? session.groups : [],
        }),
      });
    }
  }, [history, session]);

  if (isLoginMSAL() && isLoginGoogle()) {
    return (
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <MsalProvider instance={msalInstance}>
          <GoogleOAuthProvider clientId={googleAuth.clientId}>
            <NNavbar showLink={true} />
            <LoginComponents.LoginFormSSO loginActive={loginActive} />
          </GoogleOAuthProvider>
        </MsalProvider>
      </div>
    );
  }
  if (isLoginMSAL()) {
    return (
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <MsalProvider instance={msalInstance}>
          <NNavbar showLink={true} />
          <LoginComponents.LoginFormSSO loginActive={loginActive} />
        </MsalProvider>
      </div>
    );
  }
  if (isLoginGoogle()) {
    return (
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <GoogleOAuthProvider clientId={googleAuth.clientId}>
          <NNavbar showLink={true} />
          <LoginComponents.LoginFormSSO loginActive={loginActive} />
        </GoogleOAuthProvider>
      </div>
    );
  }
  return <></>;
};
export default LoginSSO;
