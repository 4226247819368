import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Button, ButtonGroup, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { RowItem } from "components/Nimbiv2/styled";
import { getHelpItemTypes } from "store/actions/async/help-async.actions";
import HelpCenterDetailContainer from "./HelpCenterDetailContainer";
import ManualContainer from "./ManualContainer";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { NButton, NInputSearch } from "components/Nimbiv2";
import { useTranslation } from "react-i18next";
import { ModalReport } from "components";

const HelpCenterView = () => {
  const { t } = useTranslation();
  const [selectTab, setSelectTab] = useState("faq");
  const [item, setItem] = useState(null);
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState("questions");
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHelpItemTypes());
  }, [dispatch]);

  const helpItemTypes = useSelector((state) => state.help.helpItemTypes);
  useEffect(() => {
    const data = helpItemTypes.filter(
      (item) => item?.section === tab && item.key === "faq"
    );
    if (data?.length > 0) {
      setItem(data[0]);
    }
  }, [helpItemTypes, tab, selectTab]);
  return (
    <Container fluid style={{ paddingBottom: "40px" }} className="m-0">
      <ContainerMenu>
        <Col md="4">
          <div>
            <ButtonGroupDesign>
              <Button
                className={tab === "questions" ? "active" : ""}
                onClick={() => {
                  setItem(null);
                  setTab("questions");
                  setSelectTab("faq");
                  setSearch("");
                }}
              >
                {_.upperFirst(t("questions"))}
              </Button>
              <Button
                className={tab === "tutorials" ? "active" : ""}
                onClick={() => {
                  setItem(null);
                  setTab("tutorials");
                  setSelectTab(null);
                  setSearch("");
                }}
              >
                {_.upperFirst(t("tutorials"))}
              </Button>
              <Button
                className={tab === "manual" ? "active" : ""}
                onClick={() => {
                  setItem(null);
                  setTab("manual");
                  setSelectTab(null);
                  setSearch("");
                }}
              >
                {_.upperFirst(t("manual"))}
              </Button>
            </ButtonGroupDesign>
          </div>
          {tab !== "manual" && (
            <NInputSearch
                style={{ border: "none", marginTop: "28px" }}
                placeholder="Escribe tu pregunta o palabra clave"
                value={search}
                onChange={(event) => {
                  setSearch(event.target.value);
              }}
            />
          )}
          <div style={{ marginTop: "28px" }}>
            {helpItemTypes
              .filter((item) => item?.section === tab)
              .map((item, key) => {
                return (
                  <CardItemMenu
                    key={`carditemmenu-${key}`}
                    className={
                      item.key === selectTab
                        ? "active d-flex align-items-center"
                        : "d-flex align-items-center"
                    }
                    onClick={() => {
                      setSelectTab(item.key);
                      setSearch("");
                      setItem(item);
                    }}
                    title={item.name}
                  >
                    <div className="mr-auto">{item.name}</div>
                    {item.key === selectTab && (
                      <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                    )}
                  </CardItemMenu>
                );
              })}
          </div>
          <ContactContainer>
            <div className="title-contact">¿Todavía necesitas ayuda?</div>
            <div className="detail-contact">
              Envíanos tu pregunta, estamos para ayudarte
            </div>
            <NButton
              style={{ marginTop: "16px" }}
              border="24px"
              colorbutton="#1E22CE"
              hoverbuttom="#1E22CE"
              onClick={() => {
                setShow(true);
              }}
            >
              Contáctanos
            </NButton>
          </ContactContainer>
        </Col>
        <Col md="8" style={ tab === "manual" ? { minWidth:'768px'} : {}} >
          {tab === "manual" ? 
            <ManualContainer />
           :
          <HelpCenterDetailContainer
            itemType={selectTab}
            search={search}
            itemSelected={item}
          ></HelpCenterDetailContainer>}
          {/* <CardNimbi padding="24px"></CardNimbi> */}
        </Col>
      </ContainerMenu>
      <ModalReport
        isVisible={show}
        onClose={() => {
          setShow(false);
        }}
      />
    </Container>
  );
};

export default HelpCenterView;

const ContainerMenu = styled(RowItem)`
  .active {
    color: var(--primary-dark, #14199f) !important;
    border-radius: 8px;
    background: var(--gray-scale-800, #d9e1f2);
    cursor: default;
  }
`;

const CardItemMenu = styled.div`
  padding: 8px 16px;
  background: #f8fbff;
  border-radius: 8px;
  color: var(--gray-scale-1000, #9cabc2);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.16px;
  margin-bottom: 8px;
  cursor: pointer;
`;

const ContactContainer = styled.div`
  border-radius: 8px;
  background: var(--primary-light, #8da6ff);
  padding: 16px;
  .title-contact {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
  .detail-contact {
    margin-top: 8px;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.14px;
  }
`;

const ButtonGroupDesign = styled(ButtonGroup)`
  width: 100%;
  .btn {
    border-radius: 8px;
    padding: 6px;
  }
  .btn-primary {
    background-color: white;
    border-color: white;
    color: #2a3b53;
  }
  .active {
    background-color: #d9e1f2 !important;
    border-color: #14199f !important;
    color: #14199f !important;
  }
  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:focus .focus {
    background-color: #d9e1f2 !important;
    border-color: #14199f !important;
    color: #14199f !important;
  }
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(23 82 137 / 50%);
  }
`;
